<template>
  <div>
    <review-list-item :title="'product'">
      <div slot="content">
        <b-row class="no-gutters">
          <b-col>
            <p class="mb-0">{{ productName }}</p>
            <p class="font-weight-bold">
              {{ $t("totalVideos", { total: productTotalVideo }) }}
            </p>
            <p class="mb-0">{{ $t("panel") }}</p>
            <p class="font-weight-bold">
              {{ $t("totalRespondents", { total: productTotalRespondents }) }}
            </p>
          </b-col>
          <b-col class="col-auto">
            <img
              src="./../../assets/general-icons/trash.svg"
              class="delete__icon clickable mr-1"
              @click="openDeleteConfirmationDialog"
            />
          </b-col>
        </b-row>
      </div>
    </review-list-item>
    <review-list-item :title="'target'">
      <div slot="content">
        <b-row class="no-gutters">
          <b-col>
            <p class="mb-0">{{ $t("country") }}</p>
            <p class="font-weight-bold">{{ targetCountry.countryName }}</p>
            <p class="mb-0">{{ $t("category") }}</p>
            <p class="font-weight-bold">{{ targetCategory }}</p>
            <p class="mb-0">{{ $t("audience") }}</p>
            <p class="font-weight-bold">
              {{ $t(`page.audience.${targetAudience}.title`) }}
            </p>
            <p class="mb-0">{{ $t("gender") }}</p>
            <p class="font-weight-bold">{{ targetGender }}</p>
            <p class="mb-0">{{ $t("age") }}</p>
            <p class="font-weight-bold">{{ targetAgeRange }}</p>
            <p class="mb-0">{{ $t("region") }}</p>
            <p class="font-weight-bold">{{ targetRegion }}</p>
          </b-col>
          <b-col class="col-auto">
            <b-button
              size="sm"
              class="text-uppercase"
              variant="outline-dark"
              @click="goToStep('Audience')"
            >
              <img
                src="./../../assets/general-icons/pencil-alt-2.svg"
                class="action__icon clickable mr-1"
              />
              <span>{{ $t("button.edit") }}</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </review-list-item>
    <review-list-item :title="'video'">
      <div slot="content">
        <b-row class="no-gutters justify-content-end">
          <b-col class="col-auto">
            <b-button
              size="sm"
              class="text-uppercase"
              variant="outline-dark"
              @click="goToStep('Stimulus')"
            >
              <img
                src="./../../assets/general-icons/pencil-alt-2.svg"
                class="action__icon clickable mr-1"
              />
              <span>{{ $t("button.edit") }}</span>
            </b-button>
          </b-col>
        </b-row>
        <b-row class="no-gutters">
          <b-col
            v-for="stimulus in stimulusList"
            :key="stimulus._id"
            class="col-12 col-md-6 col-xl-4 py-3 pr-0 pr-md-2"
          >
            <b-card
              class="card-item h-100"
              no-body
              footer-bg-variant="white"
              footer-class="border-top-0 px-3 py-4"
            >
              <b-card-body class="p-0 bg-black">
                <video
                  class="p-0"
                  width="100%"
                  height="100%"
                  preload="metadata"
                  :src="`${stimulus.src}#t=1.5`"
                ></video>
              </b-card-body>
              <template v-slot:footer>
                <p class="title__3 mb-0">
                  {{ stimulus.name | fileName }}
                </p>
              </template>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </review-list-item>
  </div>
</template>

<script>
import ReviewListItem from "./ReviewListItem";
export default {
  components: {
    ReviewListItem
  },
  computed: {
    activeProject() {
      return this.$store.getters["project/getActiveProject"];
    },
    projectName() {
      return this.activeProject.name || null;
    },
    activeProjectPackage() {
      return this.$store.getters["wizard/getActiveProjectPackageInfo"];
    },
    productName() {
      return this.activeProjectPackage.name;
    },
    productTotalVideo() {
      return this.activeProjectPackage.totalVideo;
    },
    productTotalRespondents() {
      return this.activeProjectPackage.totalRespondents;
    },
    targetCountry() {
      return this.$store.getters["audience/getSelectedCountry"];
    },
    targetCategory() {
      const brandCategoryId = this.$store.getters["audience/getBrandCategory"];
      if (brandCategoryId) {
        const categoryInfo = this.$store.getters[
          "audience/getBrandCategoryOptions"
        ].find(category => category.id === brandCategoryId);
        return categoryInfo ? categoryInfo.name : "";
      } else return "";
    },
    targetAudience() {
      return this.$store.getters["audience/getTargetGroup"];
    },
    targetGender() {
      const genderList = this.$store.getters["audience/getSelectedGenderList"];
      return genderList
        .map(ageItem => {
          return this.$t(ageItem);
        })
        .join(", ");
    },
    targetAgeRange() {
      const ageRangeList = this.$store.getters[
        "audience/getSelectedAgeRangeList"
      ];
      return ageRangeList
        .map(ageRangeItem => {
          return this.$t(`page.audience.ageRange.${ageRangeItem}`);
        })
        .join(", ");
      // return ageRangeList.join(", ");
    },
    targetRegion() {
      const regionList = this.$store.getters["audience/getSelectedRegionList"];
      return regionList.map(region => region.name).join(", ");
    },
    stimulusList() {
      return this.$store.getters["stimulus/getStimulusList"];
    }
  },
  methods: {
    goToStep(routeName) {
      this.$router.push({ name: routeName });
    },
    openDeleteConfirmationDialog() {
      this.$store.commit("confirm/SET_CONFIRM", {
        titleText: "dialog.confirm.deleteProjectTitle",
        messageText: "dialog.confirm.deleteProjectMsg",
        messageParams: { projectName: this.projectName },
        confirmBtnCallback: this.deleteProject,
        type: "DELETE"
      });
    },
    deleteProject() {
      this.$store.commit("loader/SET_LOADER", {});
      this.$store
        .dispatch("project/deleteProjectByClientId", this.activeProject._id)
        .then(() => {
          this.$router.push({
            name: "Dashboard"
          });
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch("loader/close");
        });
    }
  }
};
</script>

<style scoped>
.delete__icon {
  width: 20px;
  filter: invert(49%) sepia(7%) saturate(539%) hue-rotate(167deg)
    brightness(90%) contrast(88%);
}
.action__icon {
  width: 14px;
  filter: invert(49%) sepia(7%) saturate(539%) hue-rotate(167deg)
    brightness(90%) contrast(88%);
}
</style>
