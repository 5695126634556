import { render, staticRenderFns } from "./CustomerUserReviewList.vue?vue&type=template&id=66c54297&scoped=true&"
import script from "./CustomerUserReviewList.vue?vue&type=script&lang=js&"
export * from "./CustomerUserReviewList.vue?vue&type=script&lang=js&"
import style0 from "./CustomerUserReviewList.vue?vue&type=style&index=0&id=66c54297&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66c54297",
  null
  
)

export default component.exports