<template>
  <div>
    <review-list-item :title="'target'">
      <div slot="content">
        <b-row class="no-gutters">
          <b-col>
            <p class="mb-0">{{ $t("country") }}</p>
            <p class="font-weight-bold">{{ targetCountry.countryName }}</p>
            <p class="mb-0">{{ $t("category") }}</p>
            <p class="font-weight-bold">{{ targetCategory }}</p>
            <p class="mb-0">{{ $t("audience") }}</p>
            <p class="font-weight-bold">
              {{ $t(`page.audience.${targetAudience}.title`) }}
            </p>
            <p class="mb-0">{{ $t("refineDemographics") }}</p>
            <p class="font-weight-bold">{{ targetRefineAudience }}</p>
            <p class="mb-0">{{ $t("gender") }}</p>
            <p class="font-weight-bold">{{ targetGender }}</p>
            <p class="mb-0">{{ $t("age") }}</p>
            <p class="font-weight-bold">{{ targetAgeRange }}</p>
            <p class="mb-0">{{ $t("region") }}</p>
            <p class="font-weight-bold">{{ targetRegion }}</p>
            <p class="mb-0">{{ $t("numberOfRespondents") }}</p>
            <p class="font-weight-bold">{{ targetTotalRespondents }}</p>
          </b-col>
          <b-col class="col-auto">
            <b-button
              size="sm"
              class="text-uppercase"
              variant="outline-dark"
              @click="goToStep('Audience')"
            >
              <img
                src="./../../assets/general-icons/pencil-alt-2.svg"
                class="action__icon clickable mr-1"
              />
              <span>{{ $t("button.edit") }}</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </review-list-item>
    <review-list-item :title="'video'">
      <div slot="content">
        <b-row class="no-gutters justify-content-end">
          <b-col class="col-auto">
            <b-button
              size="sm"
              class="text-uppercase"
              variant="outline-dark"
              @click="goToStep('Stimulus')"
            >
              <img
                src="./../../assets/general-icons/pencil-alt-2.svg"
                class="action__icon clickable mr-1"
              />
              <span>{{ $t("button.edit") }}</span>
            </b-button>
          </b-col>
        </b-row>
        <b-row class="no-gutters">
          <b-col
            v-for="stimulus in stimulusList"
            :key="stimulus._id"
            class="col-12 col-md-6 col-xl-4 py-3 pr-0 pr-md-2"
          >
            <b-card
              class="card-item h-100"
              no-body
              footer-bg-variant="white"
              footer-class="border-top-0 px-3 py-4"
            >
              <b-card-body class="p-0 bg-black">
                <video
                  class="p-0"
                  width="100%"
                  height="100%"
                  preload="metadata"
                  :src="`${stimulus.src}#t=1.5`"
                ></video>
              </b-card-body>
              <template v-slot:footer>
                <p class="title__3 mb-0">
                  {{ stimulus.name | fileName }}
                </p>
              </template>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </review-list-item>
    <review-list-item :title="'questions'">
      <div slot="content">
        <b-row class="no-gutters">
          <b-col>
            <div v-for="(question, index) in questionList" :key="index">
              <div>{{ $t("questionIndex", { index: index + 1 }) }}</div>
              <p class="font-weight-bold h6">
                {{ question.text }}
              </p>
            </div>
          </b-col>
          <b-col class="col-auto">
            <b-button
              size="sm"
              class="text-uppercase"
              variant="outline-dark"
              @click="goToStep('Questions')"
            >
              <img
                src="./../../assets/general-icons/pencil-alt-2.svg"
                class="action__icon clickable mr-1"
              />
              <span>{{ $t("button.edit") }}</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </review-list-item>
  </div>
</template>

<script>
import ReviewListItem from "./ReviewListItem";
export default {
  components: {
    ReviewListItem
  },
  computed: {
    targetCountry() {
      return this.$store.getters["audience/getSelectedCountry"];
    },
    targetCategory() {
      const brandCategoryId = this.$store.getters["audience/getBrandCategory"];
      if (brandCategoryId) {
        const categoryInfo = this.$store.getters[
          "audience/getBrandCategoryOptions"
        ].find(category => category.id === brandCategoryId);
        return categoryInfo ? categoryInfo.name : "";
      } else return "";
    },
    targetAudience() {
      return this.$store.getters["audience/getTargetGroup"];
    },
    targetRefineAudience() {
      const isReficeAudience = this.$store.getters[
        "audience/getActiveRefineAudience"
      ];
      return isReficeAudience ? "Yes" : "No";
    },
    targetGender() {
      const genderList = this.$store.getters["audience/getSelectedGenderList"];
      return genderList
        .map(ageItem => {
          return this.$t(ageItem);
        })
        .join(", ");
    },
    targetAgeRange() {
      const ageRangeList = this.$store.getters[
        "audience/getSelectedAgeRangeList"
      ];
      return ageRangeList
        .map(ageRangeItem => {
          return this.$t(`page.audience.ageRange.${ageRangeItem}`);
        })
        .join(", ");
    },
    targetRegion() {
      const regionList = this.$store.getters["audience/getSelectedRegionList"];
      return regionList.map(region => region.name).join(", ");
    },
    targetTotalRespondents() {
      return this.$store.getters["wizard/getActiveProjectPackageInfo"]
        .totalRespondents;
    },
    questionList() {
      return this.$store.getters["question/getQuestionList"];
    },
    stimulusList() {
      return this.$store.getters["stimulus/getStimulusList"];
    }
  },
  methods: {
    goToStep(routeName) {
      this.$router.push({ name: routeName });
    }
  }
};
</script>

<style scoped>
.action__icon {
  width: 14px;
  filter: invert(49%) sepia(7%) saturate(539%) hue-rotate(167deg)
    brightness(90%) contrast(88%);
}
</style>
