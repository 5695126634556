<template>
  <div>
    <b-card no-body class="rounded-0 p-3">
      <b-row class="no-gutters justify-content-between">
        <b-col>
          <h6 class="font-weight-bold text-uppercase">{{ $t(title) }}</h6>
        </b-col>
        <b-col class="col-auto">
          <b-icon
            class="clickable"
            :icon="visible ? 'chevron-up' : 'chevron-down'"
            variant="gray"
            :class="visible ? null : 'collapsed'"
            :aria-expanded="visible ? 'true' : 'false'"
            @click="visible = !visible"
          ></b-icon>
        </b-col>
      </b-row>
      <b-collapse v-model="visible">
        <b-card-text class="bg-light p-3 mt-3">
          <slot name="content" />
        </b-card-text>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      visible: true
    };
  }
};
</script>

<style scoped></style>
