<template>
  <b-row class="no-gutters">
    <b-col class="col-12 col-md-8 col-lg-6">
      <h4 class="font-weight-normal text-muted title__2 mb-4">
        {{ $t("page.review.title") }}
      </h4>
      <component :is="activeComponent" />
    </b-col>
  </b-row>
</template>

<script>
import Utils from "./../Utils";

import RegularUserReviewList from "./../components/Review/RegularUserReviewList";
import CustomerUserReviewList from "./../components/Review/CustomerUserReviewList";

export default {
  components: {
    RegularUserReviewList,
    CustomerUserReviewList
  },
  computed: {
    activeUserType() {
      return this.$store.getters["auth/getUserType"];
    },
    activeComponent() {
      if (this.activeUserType === Utils.USER_TYPE.REGULAR)
        return "regular-user-review-list";
      else if (this.activeUserType === Utils.USER_TYPE.CUSTOMER)
        return "customer-user-review-list";
      else return "";
    }
  }
};
</script>

<style scoped></style>
